exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-board-of-directors-jsx": () => import("./../../../src/pages/about-us/board-of-directors.jsx" /* webpackChunkName: "component---src-pages-about-us-board-of-directors-jsx" */),
  "component---src-pages-about-us-corporate-jsx": () => import("./../../../src/pages/about-us/corporate.jsx" /* webpackChunkName: "component---src-pages-about-us-corporate-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-about-us-leadership-jsx": () => import("./../../../src/pages/about-us/leadership.jsx" /* webpackChunkName: "component---src-pages-about-us-leadership-jsx" */),
  "component---src-pages-about-us-our-purpose-and-values-jsx": () => import("./../../../src/pages/about-us/our-purpose-and-values.jsx" /* webpackChunkName: "component---src-pages-about-us-our-purpose-and-values-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-corporate-index-jsx": () => import("./../../../src/pages/corporate/index.jsx" /* webpackChunkName: "component---src-pages-corporate-index-jsx" */),
  "component---src-pages-esg-overview-jsx": () => import("./../../../src/pages/esg-overview.jsx" /* webpackChunkName: "component---src-pages-esg-overview-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-investors-agm-jsx": () => import("./../../../src/pages/investors/AGM.jsx" /* webpackChunkName: "component---src-pages-investors-agm-jsx" */),
  "component---src-pages-investors-analyst-coverage-jsx": () => import("./../../../src/pages/investors/analyst-coverage.jsx" /* webpackChunkName: "component---src-pages-investors-analyst-coverage-jsx" */),
  "component---src-pages-investors-index-jsx": () => import("./../../../src/pages/investors/index.jsx" /* webpackChunkName: "component---src-pages-investors-index-jsx" */),
  "component---src-pages-investors-investing-with-us-jsx": () => import("./../../../src/pages/investors/investing-with-us.jsx" /* webpackChunkName: "component---src-pages-investors-investing-with-us-jsx" */),
  "component---src-pages-investors-presentations-and-events-jsx": () => import("./../../../src/pages/investors/presentations-and-events.jsx" /* webpackChunkName: "component---src-pages-investors-presentations-and-events-jsx" */),
  "component---src-pages-investors-shareholder-information-jsx": () => import("./../../../src/pages/investors/shareholder-information.jsx" /* webpackChunkName: "component---src-pages-investors-shareholder-information-jsx" */),
  "component---src-pages-investors-us-investors-jsx": () => import("./../../../src/pages/investors/us-investors.jsx" /* webpackChunkName: "component---src-pages-investors-us-investors-jsx" */),
  "component---src-pages-job-opportunities-jsx": () => import("./../../../src/pages/job-opportunities.jsx" /* webpackChunkName: "component---src-pages-job-opportunities-jsx" */),
  "component---src-pages-media-releases-jsx": () => import("./../../../src/pages/media-releases.jsx" /* webpackChunkName: "component---src-pages-media-releases-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-oko-gold-project-jsx": () => import("./../../../src/pages/oko-gold-project.jsx" /* webpackChunkName: "component---src-pages-oko-gold-project-jsx" */),
  "component---src-pages-tocantinzinho-jsx": () => import("./../../../src/pages/tocantinzinho.jsx" /* webpackChunkName: "component---src-pages-tocantinzinho-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */)
}

